<template>
  <div>
    <CompanyEmployeeList />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'companyEmployee',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Müşteri Yönetim Paneli', route: '/musteri-yonetim-paneli' },
      { title: 'Çalışan Listesi' },
    ]);
  },
  components: {
    CompanyEmployeeList: () => import('@/components/companyEmployee/List'),
  },
};
</script>
